<template>
  <div class="page-nation-af">
    
    <h3 class="title">核心指标</h3>
    <div :class="itemClass">
      <div class="score" v-for="(item, idx) in coreData" :key="idx">
        <div class="num">
          <strong>{{item.value || '-'}}</strong>
          <span>{{item.unit}}</span>
        </div>
        <div class="name"><i :class="item.column"></i>{{item.title}}</div>
      </div>
    </div>

    
    <div class="box-list">
      <a-table size="default" ref="table" rowKey="id"
        :locale="{emptyText: '暂无数据'}"
        :loading="loadingR"
        :pagination="false"
        :columns="columns"
        :dataSource="regionData"
        :customRow="clickRegionRow"
      >
        <span slot="index" slot-scope="text,record,index">
          {{ index + 1 }}
        </span>
      </a-table>
    </div>
    <div class="box-list">
      <a-table size="default" ref="table" rowKey="id"
        :locale="{emptyText: '暂无数据'}"
        :loading="loadingA"
        :pagination="false"
        :columns="columns"
        :dataSource="areaData"
        :customRow="clickAreaRow"
      >
        <span slot="index" slot-scope="text,record,index">
          {{ index + 1 }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm, getVolvoAffiliationOtps, getVolvoRoleOtps, getGroupOtpsById, getCompanyOtps, getDepartmentOtpsById, getRegionOtps, getCompanyOtpsById, getBusinessOtps, getEntryStateOtps,gettargetModel} from '@/api'
import {normalDate, toRange} from '@/utils/formatDate'
import pick from 'lodash.pick'
import actions from '@/api/action'
import CascadeFilter from '@/components/CascadeFilter'
import SelectSeller from '@/components/SelectSeller'
import {getRole} from '@/utils/getRole'
import NavBar from '@/components/afNavbarDealer.vue';
import { getFormatDate } from "@/dbCommon/utils";


export default {
  name: '',
  props: {
  },
  components: {
    //CascadeFilter
  },
  data() {
    return {
      loadingR: false,
      loadingA: false,
      coreData: [],
      regionData: [],
      areaData: [],
    };
  },
  computed:{
    itemClass() {
      if (this.coreData.length % 2 == 0) {
        return 'box-core';
      }
      return 'box-core even';
    },
    columns() {
      return  [
        {
          title: " ",
          dataIndex: "name",
          width: "100px",
        },
        {
          title: "排行",
          dataIndex: "index",
          scopedSlots: { customRender: 'index' },
        },
        {
          title: '接待量',
          dataIndex: "count",
        },
        {
          title: "总分",
          dataIndex: "score",
        },
        {
          title: "增换购客户数",
          dataIndex: "zhg",
        },
        {
          title: "AI铭牌匹配率",
          dataIndex: "match_rate"
        },
        {
          title: "增换购",
          dataIndex: "label1"
        },
        {
          title: "电子结算单",
          dataIndex: "label2"
        },
        {
          title: "非车险产品",
          dataIndex: "label3"
        },
        {
          title: "保养套餐",
          dataIndex: "label4"
        },
        /*
        {
          title: "APP推荐绑定",
          dataIndex: ""
        },
        {
          title: "VHC健康报告",
          dataIndex: "",
        },
        */
      ]
    }
  },
  methods: {
    clickRegionRow(record, index) {
      return {
          style: {
            cursor: 'pointer'
          },
          on: {
             click: () => {
               this.$router.push({name: 'afRegion', query: {...this.query,region_id:record.id}});
             }
          }
        }
    },
    clickAreaRow(record, index) {
      return {
          style: {
            cursor: 'pointer'
          },
          on: {
             click: () => {
               this.$router.push({name: 'afArea', query: {...this.query,area_id:record.id}});
             }
          }
        }
    },
    getDataBySearch(q) {
      this.query = {
        ...this.query,
        ...q,
      }
      this.onSearch();
    },
    onSearch(pageNum, pageSize) {
      this.pageNum = pageNum || this.pageNum;
      if (this.rangeDate) {
        const rd = normalDate(this.rangeDate);
        this.query.start_date = rd[0];
        this.query.end_date = rd[1];
      }
      this.loadingR = true;
      this.loadingA = true;

      const params = {...this.query};
      postForm('/h5/sa_crop/index', params).then(res => {
        this.coreData = res.data.totalAccount || [];
      });
      postForm('/h5/sa_crop/indexRegion', params).then(res => {
        this.loadingR = false;
        this.regionData = res.data || [];
      });
      postForm('/h5/sa_crop/indexArea', params).then(res => {
        this.loadingA = false;
        this.areaData = res.data || [];
      });
      
    },
  },
  created() {
    const q = this.$router.currentRoute.query || {};
    this.role = getRole();
    this.query = {
      ...this.query,
      ...q,
    };
    this.onSearch();
  },
}
</script>
<style scoped lang="less">
.page-nation-af {
}
</style>
