<template>
  <fragment> 
    <country ref="child" v-if="role.isVolvo"/>
    <countryDefault ref="child" v-else />
  </fragment> 
</template>

<script>
import Vue from 'vue'
import {getRole} from '@/utils/getRole';
import country from './country.vue';
import countryDefault from './countryDefault.vue';


export default {
  data() {
      return {
        role: getRole(),
      }
  },
  components:{
    country,
    countryDefault,
  },
  methods: {
    getDataBySearch(q) {
      this.$refs.child && this.$refs.child.getDataBySearch(q);
    },
  },
  created() {
  },
  mounted () {
  },
}
</script>

<style lang="less">

</style>


